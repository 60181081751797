import { ErrorTypes } from '@shared/api/errorTypes';
import { ProblemDetails } from '@shared/api/problemDetails';
import { NotificationError } from '@shared/types/NotificationError';

export class ProblemDetailsConverter {
  /**
   * Converts the ErrorType into resource key.
   */
  public static convertToResourceKey(problemDetails: ProblemDetails | undefined): string | null {
    if (!problemDetails) {
      return null;
    }

    switch (problemDetails.type) {
      case ErrorTypes.ReportGenerator_NoTherapyDays:
        return 'Error_ReportGenerator_NoTherapyDays';
      case ErrorTypes.SdCard_BadUploadRequest:
        return 'Error_UploadingSdCard_InvalidData';
      case ErrorTypes.SdCard_MissingTherapyStatistics:
        return 'Error_UploadingSdCard_NoData';
      case ErrorTypes.SdCard_AllStatisticsBeforeLimit:
        return 'Error_UploadingSdCard_AllStatisticsBeforeLimit';
      case ErrorTypes.SdCard_AllStatisticsAfterLimit:
        return 'Error_UploadingSdCard_AllStatisticsAfterLimit';
      case ErrorTypes.SdCard_InvalidP2_8FirmwareVersion:
        return 'Error_UploadingSdCard_P28FwIsLow';
      case ErrorTypes.SdCard_InvalidP2_8FileExtension:
        return 'Error_UploadingSdCard_P28InvalidFileExtension';
      case ErrorTypes.SdCard_InvalidP3FirmwareVersion:
        return 'Error_UploadingSdCard_P3FwIsLow';
      case ErrorTypes.SdCard_InvalidP3FileExtension:
        return 'Error_UploadingSdCard_P3InvalidFileExtension';
      case ErrorTypes.SdCard_DeviceDoesNotBelongToOrganisation:
        return 'Error_UploadingSdCard_WrongOrganisation';
      case ErrorTypes.SdCard_InactivePic:
        return 'Error_UploadingSdCard_InactivePic';
      case ErrorTypes.ReportGenerator_PeriodTooLong:
        return 'Error_ReportGenerator_PeriodTooLong';
      case ErrorTypes.TooManyRequests:
        return 'Error_TooManyRequests';
      case ErrorTypes.DeleteOrganisationForbidden:
        return 'Error_DeleteOrganisationForbidden';
      case ErrorTypes.FileIsEmpty:
        return 'DeviceList.MoveDevices.MoveDevicesWizard.SettingParamsStep.Error.FileIsEmpty';
      case ErrorTypes.TooManyDevicesInFile:
        return 'DeviceList.MoveDevices.MoveDevicesWizard.SettingParamsStep.Error.TooManyDevicesInFile';
      default:
        return null;
    }
  }

  /**
   * Checks if provided value is problem details and if true using User-Defined Type Guards
   * protect code in true condition to interpret it as another type.
   * @param value any value that we should check if it is problem details error.
   * @returns true if object is problem details
   */
  public static isProblemDetailsError(value: any): value is ProblemDetails {
    if (value && value.status && value.type) {
      return true;
    }

    return false;
  }

  /**
   * Converts an exception which is raised by and api call into error object
   * which is shown by the notification bar.
   */
  public static convertApiExceptionToError(e: any): NotificationError {
    let body = e?.response?.data ?? {};
    if (body instanceof ArrayBuffer) {
      try {
        const content = String.fromCharCode.apply(null, Array.from(new Uint8Array(body)));
        body = JSON.parse(content);
      } catch {
        return { resourceKey: undefined };
      }
    }

    const problemDetails: ProblemDetails = body;
    const resourceKey = ProblemDetailsConverter.convertToResourceKey(problemDetails);
    const error: NotificationError = resourceKey ? { resourceKey } : { resourceKey: undefined };
    return error;
  }
}
