import {
  DeviceAssignmentFilters,
  PatientNoteFilters,
  PrismaAppUsageFilter,
  SharingStatusFilters,
} from '@shared/constants/filters';
import { PatientFilterCategory } from '@shared/constants/PatientFilterCategory';
import { SortDirections } from '@shared/constants/SortDirections';
import { DeviceSortingFields, PatientSortingFields } from '@shared/constants/sortingFields';

export type PatientQuestionnaireStatusFilter = 'Empty' | 'Answered' | 'WaitingForAnswer' | 'Expired';

export interface PatientPageRequestParams {
  page: number;
  count: number;
  sortField: PatientSortingFields;
  sortDirection: SortDirections;
  usersInCharge: string[];
  deviceAssignmentFilter: DeviceAssignmentFilters;
  patientNoteFilter: PatientNoteFilters;
  recentlyChangedSettings: boolean;
  recentlyProblematicOutgoingSettings: boolean;
  prismaAppUsageFilter: PrismaAppUsageFilter;
  category: PatientFilterCategory;
  sharingStatus: SharingStatusFilters;
  sharedByMeToTenant: string;
  sharedToMeFromTenant: string;
  searchQuery: string | null;
  medicalConditionId: number | null;
  withActiveReminder: boolean;
  questionnaireTitle: string | null;
  questionnaireStatusFilter: PatientQuestionnaireStatusFilter;
}

export interface DevicePageRequestParams {
  page: number;
  pageItemsCount: number;
  sortDirection: SortDirections;
  sortField: DeviceSortingFields;
  missingDataFilter: boolean;
  wrongDeviceTimeFilter: boolean;
  lastUploadFilter: boolean;
  lastReceivedFromPatientAppFilter: boolean;
  lastTransmissionFilter: boolean;
  telemonitoringDisabledFilter: boolean;
  includeOnlyOutOfUse: boolean;
  recentlyChangedDeviceConfig: boolean;
  problemsWithDeviceConfig: boolean;
}

export interface DeactivatedDevicePageRequestParams {
  page: number;
  pageItemsCount: number;
  searchQuery?: string;
  sortDirection: SortDirections;
}

export interface UpdateOutOfUseRequestParams {
  serialNumber: string;
  isOutOfUse: boolean;
}

export interface PatientQuestionnairePageFilter {
  searchQuery: string | null;
  category: PatientFilterCategory | null;
  medicalConditionId: number | null;
  pageNumber: number;
}

export interface PatientQuestionnairePageItem {
  prismaCloudPatientId: string;
  lastName: string;
  firstName: string;
  tenant: string;
  organisationName: string;
  status: PatientQuestionnaireSendStatus;
}

export type PatientQuestionnaireSendStatus = 'Possible' | 'Pending';

export const toApiSortDirection = (sortDirection: SortDirections) =>
  sortDirection === SortDirections.Ascending ? SortDirections.Ascending : SortDirections.Descending;
