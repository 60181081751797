import { GetDisabledLanguages } from '@shared/config/feature-flags';

export interface Culture {
  languageName: string;
  languageCulture: string;
  dayJSLocale: string;
}

export const languageCodeEnglish = 'en';

// Language names can be found there
// https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
const allCultures: Culture[] = [
  {
    languageName: 'English (United Kingdom)',
    languageCulture: languageCodeEnglish,
    dayJSLocale: 'en-gb',
  },
  {
    languageName: 'English (United States)',
    languageCulture: 'en-us',
    dayJSLocale: 'en',
  },
  {
    languageName: 'Français',
    languageCulture: 'fr',
    dayJSLocale: 'fr',
  },
  {
    languageName: 'Deutsch',
    languageCulture: 'de',
    dayJSLocale: 'de',
  },
  {
    languageName: '中文',
    languageCulture: 'zh',
    dayJSLocale: 'zh-cn',
  },
  {
    languageName: '日本語',
    languageCulture: 'ja',
    dayJSLocale: 'ja',
  },
  {
    languageName: 'Español (México)',
    languageCulture: 'es-mx',
    dayJSLocale: 'es-mx',
  },
  {
    languageName: 'Español (España)',
    languageCulture: 'es-es',
    dayJSLocale: 'es',
  },
  {
    languageName: 'Português (Brasil)',
    languageCulture: 'pt-br',
    dayJSLocale: 'pt-br',
  },
  {
    languageName: 'Português (Portugal)',
    languageCulture: 'pt-pt',
    dayJSLocale: 'pt',
  },
  {
    languageName: 'Česky',
    languageCulture: 'cs',
    dayJSLocale: 'cs',
  },
  {
    languageName: 'Slovenčina',
    languageCulture: 'sk',
    dayJSLocale: 'sk',
  },
  {
    languageName: 'Italiano',
    languageCulture: 'it',
    dayJSLocale: 'it',
  },
  {
    languageName: 'Dansk',
    languageCulture: 'da',
    dayJSLocale: 'da',
  },
  {
    languageName: 'Polski',
    languageCulture: 'pl',
    dayJSLocale: 'pl',
  },
  {
    languageName: '한국어',
    languageCulture: 'ko',
    dayJSLocale: 'ko',
  },
  {
    languageName: 'Nederlands',
    languageCulture: 'nl',
    dayJSLocale: 'nl',
  },
  {
    languageName: 'Latviešu',
    languageCulture: 'lv',
    dayJSLocale: 'lv',
  },
  {
    languageName: 'Ελληνικά',
    languageCulture: 'el',
    dayJSLocale: 'el',
  },
  {
    languageName: 'Български',
    languageCulture: 'bg',
    dayJSLocale: 'bg',
  },
  {
    languageName: 'Türkçe',
    languageCulture: 'tr',
    dayJSLocale: 'tr',
  },
  {
    languageName: 'Svenska',
    languageCulture: 'sv',
    dayJSLocale: 'sv',
  },
  {
    languageName: 'Eesti',
    languageCulture: 'et',
    dayJSLocale: 'et',
  },
  {
    languageName: 'Norsk',
    languageCulture: 'no',
    dayJSLocale: 'nb',
  },
  {
    languageName: 'Slovenščina',
    languageCulture: 'sl',
    dayJSLocale: 'sl',
  },
  {
    languageName: 'Românește',
    languageCulture: 'ro',
    dayJSLocale: 'ro',
  },
] as const;

export const getCultures = (): Culture[] => {
  const disabledLanguages = GetDisabledLanguages();

  return disabledLanguages
    ? allCultures.filter((x) => disabledLanguages.indexOf(x.languageCulture) === -1)
    : allCultures;
};
