import React from 'react';

import { radioClasses } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { paginationItemClasses } from '@mui/material/PaginationItem';
import { createTheme } from '@mui/material/styles';
import { tabClasses } from '@mui/material/Tab';
import toggleButtonClasses from '@mui/material/ToggleButton/toggleButtonClasses';
import { pickersSlideTransitionClasses } from '@mui/x-date-pickers-pro';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

import { buttonTheme } from './theme/ButtonTheme';
import { muiCheckboxTheme } from './theme/CheckboxTheme';
import { iconButtonTheme } from './theme/IconButtonTheme';
import { CalendarDay } from '@controls/CalendarDay/CalendarDay';
import { CalendarIcon } from '@controls/icons/CalendarIcon';
import { RadioButtonIcon, radioButtonIconClasses } from '@controls/icons/RadioButtonIcon';

const getOutlinedInputClasses = (colors: { main: string; light: string; dark: string }) => ({
  backgroundColor: colors.light,
  [`& .${outlinedInputClasses.input}`]: {
    color: colors.main,
  },
  ['&:hover']: {
    backgroundColor: colors.light,
  },
  [`&.${outlinedInputClasses.focused}`]: {
    backgroundColor: colors.light,
  },
  [`& .${outlinedInputClasses.input}::placeholder`]: {
    color: colors.main,
    opacity: 1,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: colors.main,
  },
  [`&:hover:not(.${outlinedInputClasses.focused}):not(.${outlinedInputClasses.disabled}) .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: colors.main,
    },
  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: colors.dark,
    borderWidth: 1,
  },
});

const paletteGreen = {
  50: '#faffe2',
  100: '#bede1e',
  200: '#a9cb00',
  500: '#80b000',
} as const;

const paletteRed = {
  50: '#ffefef',
  200: '#ff8787',
  400: '#ff6666',
} as const;

const paletteOrange = {
  50: '#fffde7',
  200: '#f3a354',
} as const;

const paletteGrey = {
  ...grey,
  50: '#f7f7f7',
  100: '#efefef',
  200: '#DFDFDF',
  300: '#C4C4C4',
  400: '#A6A6A6',
  500: '#979797',
  600: '#6E6E6E',
  700: '#525252',
  800: '#313131',
  900: '#1d1d1d',
} as const;

export const theme = createTheme({
  palette: {
    primary: {
      main: paletteGreen[200],
      dark: paletteGreen[500],
      light: paletteGreen[50],
    },
    secondary: {
      light: paletteGrey[50],
      dark: paletteGrey[400],
      main: paletteGrey[200],
    },
    warning: {
      light: paletteOrange[50],
      dark: paletteOrange[200],
      main: paletteOrange[200],
    },
    error: {
      light: paletteRed[50],
      dark: paletteRed[400],
      main: paletteRed[200],
    },
    action: {
      disabled: paletteGrey[200],
      disabledBackground: paletteGrey[50],
      hover: paletteGreen[500],
    },
    text: {
      disabled: paletteGrey[500],
    },
    grey: paletteGrey,
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 32,
        },
        input: {
          fontSize: 16,
          fontWeight: 300,
          [`&.${inputBaseClasses.inputAdornedStart}`]: {
            paddingLeft: 0,
          },
          [`&.${inputBaseClasses.disabled}::placeholder`]: {
            opacity: 1,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          minHeight: 'unset',
          [`& .${tabClasses.root}.${tabClasses.selected}`]: {
            color:
              ownerState.textColor === 'inherit' ? 'inherit' : theme.palette[ownerState.textColor ?? 'primary'].dark,
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          fontSize: 16,
          whiteSpace: 'nowrap',
          alignItems: 'center',
          minWidth: 120,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: '#fff',
          minHeight: '38px',
          ...(ownerState.color === 'primary' && {
            ['&:hover']: {
              backgroundColor: theme.palette.primary.light,
            },
            [`&.${outlinedInputClasses.focused}`]: {
              backgroundColor: theme.palette.primary.light,
            },
            [`&:hover:not(.${outlinedInputClasses.focused}):not(.${outlinedInputClasses.disabled}) .${outlinedInputClasses.notchedOutline}`]:
              {
                borderColor: paletteGreen[100],
              },
            [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.primary.dark,
              borderWidth: 1,
            },
          }),
          [`&.${outlinedInputClasses.disabled}`]: {
            backgroundColor: theme.palette.action.disabledBackground,
          },
          ...((ownerState.color === 'error' || ownerState.error) && getOutlinedInputClasses(theme.palette.error)),
          ...(ownerState.color === 'warning' && getOutlinedInputClasses(theme.palette.warning)),
        }),
        input: ({ theme }) => ({
          height: 26,
          padding: theme.spacing(0.75, 2),
        }),
        notchedOutline: ({ theme }) => ({
          borderWidth: 1,
          borderColor: theme.palette.grey[200],
          top: -10,
        }),
      },
    },
    MuiButton: buttonTheme,
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          [`&.${toggleButtonClasses.selected}`]: {
            color: '#fff',
          },
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          zIndex: 'modal',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        icon: <RadioButtonIcon />,
        checkedIcon: <RadioButtonIcon checked={true} />,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          [`& .${radioButtonIconClasses.border}`]: {
            transition: theme.transitions.create(['fill', 'stroke'], {
              easing: theme.transitions.easing.easeIn,
              duration: theme.transitions.duration.shortest,
            }),
            fill: '#fff',
            stroke: theme.palette.grey[400],
          },
          [`& .${radioButtonIconClasses.dot}`]: {
            fill: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].main,
          },
          [`&.${radioClasses.disabled}`]: {
            cursor: 'default',
            [`& .${radioButtonIconClasses.border}`]: {
              fill: '#fff',
              stroke: theme.palette.action.disabled,
            },
            [`& .${radioButtonIconClasses.dot}`]: {
              fill: theme.palette.action.disabled,
            },
          },
          [`&:hover:not(${radioClasses.disabled})`]: {
            backgroundColor: 'transparent',
            [`& .${radioButtonIconClasses.border}`]: {
              fill: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].light,
              stroke: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].main,
            },
            [`& .${radioButtonIconClasses.dot}`]: {
              fill: theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'].dark,
            },
          },
        }),
      },
    },
    MuiDayCalendar: {
      defaultProps: {
        slots: {
          day: (pickersDayProps) => (
            <CalendarDay
              key={(pickersDayProps.day as any).toString()}
              disabled={pickersDayProps.disabled ?? false}
              date={pickersDayProps.day}
              onClick={() => pickersDayProps.onDaySelect(pickersDayProps.day)}
              isToday={pickersDayProps.today ?? false}
              outsideCurrentMonth={pickersDayProps.outsideCurrentMonth}
              selected={pickersDayProps.selected}
            />
          ),
        },
      },
      styleOverrides: {
        weekDayLabel: {
          width: 42,
        },
        slideTransition: {
          minHeight: 250,
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: 355,
        },
      },
    },
    MuiDateRangeCalendar: {
      defaultProps: {
        dayOfWeekFormatter: (day) => day,
      },
      styleOverrides: {
        monthContainer: {
          '&:not(:last-of-type)': {
            borderRight: 'none !important;',
          },
          width: 355,
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        dayOfWeekFormatter: (day) => day,
        // By default it is '@media (pointer: fine)' in selenium tests pointer is not 'fine',
        // to do workaround for this problem force desktop version of date picker
        // Now it is not important because we support only desktop version
        desktopModeMediaQuery: '@media only screen and (min-width: 2px)',
        slots: {
          openPickerIcon: CalendarIcon,
        },
        slotProps: {
          inputAdornment: {
            // put calendar button before date input
            position: 'start',
            style: {
              marginLeft: '5px',
            },
          },
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          transition: theme.transitions.create('all', { duration: theme.transitions.duration.shortest }),
          [`&.${paginationItemClasses.selected}`]: {
            color: '#ffffff',
          },
          [`&:not(.${paginationItemClasses.ellipsis}):hover`]: {
            color: '#fff',
          },
          [`&.${paginationItemClasses.ellipsis}`]: {
            cursor: 'default',
          },
        }),
      },
    },
    MuiIconButton: iconButtonTheme,
    MuiCheckbox: muiCheckboxTheme,
    MuiDateRangePicker: {
      defaultProps: {
        slotProps: {
          popper: {
            placement: 'bottom-end',
          },
          layout: {
            sx: {
              [`& .${pickersSlideTransitionClasses.root}`]: {
                minHeight: '240px',
              },
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: ({ _, theme }) => ({
          [`& .${autocompleteClasses.option}.${autocompleteClasses.focused}`]: {
            backgroundColor: theme.palette.primary.light,
          },
        }),
      },
    },
  },
});
